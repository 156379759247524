require('./bootstrap');
import Masonry from 'masonry-layout';
import InfiniteScroll from 'infinite-scroll';
import imagesLoaded from 'imagesloaded';
import Swal from 'sweetalert2';
import i18next from 'i18next';
import slick from 'slick-carousel';
import LazyLoad from "vanilla-lazyload";
import ClipboardJS from "clipboard";
import WOW from "wow.js"
import Cookies from 'js-cookie'
import iFrameResize from 'iframe-resizer'
import Filterizr from 'filterizr'
import StickySidebar from 'sticky-sidebar'

const myLazyLoad = new LazyLoad();

InfiniteScroll.imagesLoaded = imagesLoaded;

const default_lang = document.documentElement.lang.substr(0, 2);

const i18n = i18next.createInstance({
    lng: default_lang,
    fallbackLng: default_lang,
    nsSeparator: '.'
}, (err, t) => {

});
$.each(vuei18nLocales, function (lng, locale) {
    $.each(locale, function (key, trans) {
        i18n.addResources(lng, key, trans);
    });
});
window.locale = i18n;
window.Filterizr = Filterizr;

const Quizier = function () {
    const sweetDelete = function () {
        $(".warning-delete").on("click", function (event) {
            event.preventDefault();
            Swal.fire({
                title: i18n.t('i18n.areYouSureToDelete'),
                text: i18n.t('i18n.canNotRecover'),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: i18n.t('i18n.yesDeleteIt'),
                cancelButtonText: i18n.t('i18n.noCancel'),
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    if ($(this).hasClass('frm-submit')) {
                        $(this).parents('form:first').submit();
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        i18n.t('i18n.cancelled'),
                        i18n.t('i18n.operationCancelled'),
                        'error'
                    )
                }
            });
        });
    },
        sweetTrash = function () {
            $(".warning-trash").on("click", function (event) {
                event.preventDefault();
                Swal.fire({
                    title: i18n.t('i18n.areYouSure'),
                    text: i18n.t('i18n.sendItemToTrash'),
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonText: i18n.t('i18n.yesTrashIt'),
                    cancelButtonText: i18n.t('i18n.noCancel'),
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        if ($(this).hasClass('frm-submit')) {
                            $(this).parents('form:first').submit();
                        }
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            i18n.t('i18n.cancelled'),
                            i18n.t('i18n.operationCancelled'),
                            'error'
                        )
                    }
                });
            });
        },
        initSlug = function () {
            $(document).on("keyup change", '.slug_title', function () {
                var title = $(this).val();
                var titleUrlString = Quizier.convertToSlug(title);
                $(this).parent().parent().parent().find('.slug').val(titleUrlString);
            });
        },
        initSocialStream = function () {
            const elems = document.querySelectorAll('.initSocial');
            const $elems = [].slice.call(elems);
            $elems.map((elem) => {
                if (elem.childNodes.length != 0) {
                    if (typeof FB !== 'undefined') {
                        FB.XFBML.parse(elem);
                        elem.classList.remove("initSocial");
                    }
                    if (typeof twttr !== 'undefined') {
                        twttr.widgets.load(elem);
                        elem.classList.remove("initSocial");
                    }
                }
            });

            if (typeof instgrm !== 'undefined') {
                instgrm.Embeds.process();
            }
        },
        resizeiFrame = function () {
            const $allVideos = $("iframe[src*='//player.vimeo.com'], iframe[src*='//www.youtube.com']"),
                $fluidEl = $("#app-modules");
            $allVideos.each(function () {
                $(this).attr('data-aspectRatio', this.height / this.width).removeAttr('height').removeAttr('width');
            });
            $(window).resize(function () {
                var newWidth = $fluidEl.width();
                $allVideos.each(function () {
                    var $el = $(this);
                    $el.width(newWidth).height(newWidth * $el.attr('data-aspectRatio'));
                });
            }).resize();
        },
        clipboard = function () {
            var clipboard = new ClipboardJS('.btn-clip', {
                text: function (trigger) {
                    return trigger.getAttribute('aria-label');
                }
            });
            clipboard.on('success', (e) => {
                let old = e.trigger.innerHTML;
                let text = e.trigger.dataset.copied;
                e.trigger.innerHTML = text;
                setTimeout(function () {
                    e.trigger.innerHTML = old;
                }, 2000);
            });
        },
        backToTop = function () {
            $('body').append(
                "<a href='#' class='back-to-top'>" +
                "<i class='back-to-top-icon fas fa-chevron-up'></i>" +
                "</a>"
            );

            var offset = 500;
            var duration = 800;
            $(window).scroll(function () {
                if ($(this).scrollTop() > offset) {
                    $('.back-to-top').addClass('show');
                } else {
                    $('.back-to-top').removeClass('show');
                }
            });

            $('.back-to-top').click(function (e) {
                e.stopPropagation();
                $('body,html').animate({
                    scrollTop: 0
                }, duration);
                return false;
            });
        },
        makeSelect = function () {
            if ($('.select-all').length) {
                $(document).on('click', '.select-all', function () {
                    this.select();
                });
            }
        },
        makeEmbed = function () {
            if ($('.make-embed').length) {
                $(document).on('click', '.make-embed', function (e) {
                    let Url = $(this).data('url') ? $(this).data('url') : ($("#embed-url").attr('href') ? $("#embed-url").attr('href') : false);
                    let Sdk = window.variables.sdk_url;
                    if (Url && Sdk) {
                        const script = "<script>(function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(d.getElementById(id))return;js=d.createElement(s);js.id=id;js.src='" + Sdk + "';fjs.parentNode.insertBefore(js,fjs);}(document,'script','quizier-sdk'));</script>";
                        const html = '<div class="story-embed" data-url="' + Url + '" data-show-info="true"></div>';
                        const template = script + html;
                        $('#embedModal #embed_code').val(template);

                        const imageUrl = $(this).data('cover') ? $(this).data('cover') : (window.postData.embed.cover ? window.postData.embed.cover : false)
                        const title = $(this).data('title') ? $(this).data('title') : (window.postData.embed.title ? window.postData.embed.title : false)
                        if (imageUrl) {
                            $('#embedModal #embedHeader').css('background-image', 'url(' + imageUrl + ')');
                        }
                        if (title) {
                            $('#embedModal .modal-title').html(title);
                        }

                        $('#embedModal').modal({
                            show: true
                        })
                    }
                });

            }
        },
        inIframe = function () {
            if (top != self) {
                $('#embed_page a').attr('target', '_blank');
            } else {
                backToTop();
            }
        },
        // postSize = function (height) {
        //     var target = parent.postMessage ? parent : (parent.document.postMessage ? parent.document : undefined);
        //     let message = {
        //         height: height,
        //         index: window.location.href
        //     }

        //     if (typeof target != "undefined") {
        //         target.postMessage(message, "*");
        //     }
        // },
        stickyNavbar = function () {
            var t = document.querySelector(".navbar-sticky");
            if (null != t) {
                var e = t.classList,
                    a = t.offsetHeight;
                e.contains("navbar-floating") && e.contains("navbar-dark") ? window.addEventListener("scroll", function (e) {
                    500 < e.currentTarget.pageYOffset ? (t.classList.remove("navbar-dark"), t.classList.add("navbar-light", "navbar-stuck")) : (t.classList.remove("navbar-light", "navbar-stuck"), t.classList.add("navbar-dark"))
                }) : e.contains("navbar-floating") && e.contains("navbar-light") ? window.addEventListener("scroll", function (e) {
                    500 < e.currentTarget.pageYOffset ? t.classList.add("navbar-stuck") : t.classList.remove("navbar-stuck")
                }) : window.addEventListener("scroll", function (e) {
                    500 < e.currentTarget.pageYOffset ? (document.body.style.paddingTop = a + "px", t.classList.add("navbar-stuck")) : (document.body.style.paddingTop = "", t.classList.remove("navbar-stuck"))
                })
            }
        },
        navbarSearch = function () {
            var e = document.querySelectorAll('[data-toggle="search"]'),
                t = document.querySelector(".navbar-search");
            if (null !== t)
                for (var a = t.querySelector(".navbar-search-field"), r = 0; r < e.length; r++) e[r].addEventListener("click", function (e) {
                    t.classList.toggle("show"), a.focus(), e.preventDefault()
                })
        },
        passwordVisibilityToggle = function () {
            for (var a = document.querySelectorAll(".password-toggle"), e = function (e) {
                var t = a[e].querySelector(".form-control");
                a[e].querySelector(".password-toggle-btn").addEventListener("click", function (e) {
                    "checkbox" === e.target.type && (e.target.checked ? t.type = "text" : t.type = "password")
                }, !1)
            }, t = 0; t < a.length; t++) e(t)
        },
        bsAccordion = function () {
            for (var n = document.querySelectorAll(".accordion-alt"), o = function (e, t) {
                e.classList.add(t)
            }, s = function (e, t) {
                e.classList.remove(t)
            }, e = function (e) {
                for (var t = n[e].querySelectorAll('[data-toggle="collapse"]'), a = n[e].querySelectorAll(".card"), r = 0; r < t.length; r++) t[r].addEventListener("click", function () {
                    for (var e = this.parentNode.parentNode.parentNode, t = 0; t < a.length; t++) s(a[t], "card-active");
                    (this.classList.contains("collapsed") ? this.classList.contains("custom-control") ? s : o : this.classList.contains("custom-control") ? o : s)(e, "card-active")
                })
            }, t = 0; t < n.length; t++) e(t)
        },
        multilevelDropdown = function () {
            $(".dropdown-menu [data-toggle='dropdown']").on("click", function (e) {
                e.preventDefault(), e.stopPropagation(), $(this).siblings().toggleClass("show"), $(this).next().hasClass("show") || $(this).parents(".dropdown-menu").first().find(".show").removeClass("show"), $(this).parents("li.nav-item.dropdown.show").on("hidden.bs.dropdown", function () {
                    $(".dropdown-submenu .show").removeClass("show")
                })
            })
        },
        offcanvas = function () {
            for (var e = document.querySelectorAll('[data-toggle="offcanvas"]'), t = 0; t < e.length; t++) e[t].addEventListener("click", function (e) {
                e.preventDefault(), document.getElementById(e.currentTarget.dataset.offcanvasId).classList.toggle("show")
            })
        },
        tooltips = function () {
            $('body').tooltip({
                selector: '[data-toggle="tooltip"]'
            });
        },
        popovers = function () {
            $('body').popover({
                selector: '[data-toggle="popover"]'
            });
        },
        postVoting = function () {
            $(document).on('click', '.voting-button', function () {
                const url = window.variables.ranking_url;
                const action = $(this).data();
                if (!$(this).hasClass('user-voted')) {
                    $(this).parent().find('button').removeClass('user-voted')
                    axios.post(url, action).then(res => {
                        if (res.data.reload) {
                            location.reload();
                        }
                        if (res.data.success) {
                            $(this).addClass('user-voted');
                            $(this).parent().find('.voting-score strong').html(res.data.action.counts.scount)
                        } else if (res.data.message) {
                            Quizier.sweetInfo(res.data.message)
                        }
                    }).catch(err => {
                        Quizier.sweetError(err.response.data.message)
                        return false;
                    });
                }
            });
        };
    return {
        init: function () {
            if (!$('body').hasClass('quizier-initialized')) {
                this.initDarkMode();
                makeSelect()
                clipboard();
                sweetDelete();
                sweetTrash();
                initSlug();
                makeEmbed();
                inIframe();
                stickyNavbar();
                navbarSearch()
                passwordVisibilityToggle()
                bsAccordion()
                multilevelDropdown()
                offcanvas()
                tooltips()
                popovers()
                postVoting()
                this.sliderInit();
                this.logoutInit();
                this.setupSocial();
                this.socialShare();
                this.masonaryGrid();
                this.updateLazyload();
                this.sidebarSticky();
            }
        },
        sidebarSticky: function () {
            if ($('section.main').hasClass('sticky-sidebar') && $('#secondary').length > 0) {
                var stickySidebar = new StickySidebar('#secondary', {
                    topSpacing: $('header').outerHeight(true) + 10,
                    bottomSpacing: 0,
                    resizeSensor: true,
                    containerSelector: '.main-wrap',
                    innerWrapperSelector: '.widgets-wrap',
                    minWidth: 991
                });

                window.stickySidebar = stickySidebar;
            }
        },
        initDarkMode: function () {
            const darkSwitch = $("#darkSwitch");
            if (darkSwitch.length > 0) {
                initTheme()
            }

            function initTheme() {
                const e = "dark" === Cookies.get('siteMode');
                darkSwitch.prop("checked", e);
                resetTheme()
            }

            function resetTheme() {
                var $nav = $('.eq-header .navbar');
                if (darkSwitch.prop("checked")) {
                    Cookies.set('siteMode', 'dark')
                    document.body.classList.add("dark-mode");
                    $nav.addClass("navbar-dark");
                    $nav.removeClass("navbar-light");
                } else {
                    Cookies.set('siteMode', 'light')
                    document.body.classList.remove("dark-mode");
                    if ($nav.data('bg-dark') !== 'yes') {
                        $nav.addClass("navbar-light");
                        $nav.removeClass("navbar-dark");
                    }
                }
            }
            $(document).on('change', '#darkSwitch', function () {
                darkSwitch.prop("checked", $(this).prop("checked"));
                resetTheme()
            });
        },
        sliderInit: function () {
            if ($(".custom-slider").length) {
                $('.custom-slider').not('.slick-initialized').each(function () {
                    var $this = $(this);
                    let $dir = $this.attr('dir');
                    $($this).slick({
                        //Default Options
                        fade: false,
                        dots: false,
                        arrows: false,
                        rows: 0,
                        autoplay: false,
                        autoplaySpeed: 3000,
                        pauseOnHover: true,
                        lazyLoad: 'ondemand',
                        infinite: true,
                        rtl: ($dir == 'rtl' ? true : false),
                        edgeFriction: 0,
                        easing: 'linear',
                        touchThreshold: 150,
                        centerPadding: '0',
                        speed: 400,
                        slidesToShow: 3,
                        initialSlide: 0,
                        draggable: false,
                        adaptiveHeight: true,
                        variableWidth: false,
                        prevArrow: '<button type="button" data-role="none" class="slick-prev" tabindex="0" role="button"></button>',
                        nextArrow: '<button type="button" data-role="none" class="slick-next" tabindex="0" role="button"></button>',
                        centerMode: false,
                        slidesToScroll: 1,
                        setPosition: 1,
                        swipe: true,
                        touchMove: true,
                        responsive: [{
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        }, {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }]
                    }).on('afterChange', function (event, slick, currentSlide) {
                        if ($($this).hasClass('hero-slider')) {
                            var items = $('.hero-slider .animated'),
                                current = $('.hero-slider .slick-current .animated'),
                                nCurrent = $('.hero-slider .slick-slide:not(.slick-current) .animated');

                            $(current).each(function () {
                                var item = $(this),
                                    animation = item.data('animation'),
                                    animationDelay = item.data('animation-delay');
                                setTimeout(function () {
                                    item.addClass(animation + " visibleme");
                                }, animationDelay);
                            });

                            $(nCurrent).each(function () {
                                var item = $(this),
                                    animation = item.data('animation');
                                item.removeClass(animation + "visibleme");
                            });

                            $($this).find('.slick-slide.slick-current .slide-img .scale-timer').addClass("scaling");
                        }
                    }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                        var nCurrent = $('.hero-slider .slick-slide:not(.slick-current) .animated'),
                            items = $('.hero-slider .animated');
                        $(nCurrent).each(function () {
                            var item = $(this),
                                animation = item.data('animation');
                            $(item).removeClass(animation + " visibleme");
                        });
                        $($this).find('.slick-slide:not(slick-current) .slide-img .scale-timer').removeClass("scaling");
                    });
                });
                $('.custom-slider').on('touchstart touchmove touchend', function () {
                    $('.custom-slider').slick("slickSetOption", "swipe", true);
                });
                if ($(".main-slider").length) {
                    $(".main-slider").on('setPosition', function (event, slick) {
                        // var slickTrackHeight = $(this).find('.link img').height();
                        // $(this).find('.link img').css('height', slickTrackHeight + 'px');
                        slick.$slides.css('height', slick.$slideTrack.height() + 'px');
                    });
                }
                if ($(".hero-slider").length) {
                    $('.slick-slide.slick-current .slide-img .scale-timer').addClass("scaling");
                    $('.hero-slider .slick-current .animate__animated').each(function () {
                        var item = $(this),
                            animation = item.data('animation'),
                            animationDelay = item.data('animation-delay');
                        $(item).removeClass(animation);
                        setTimeout(function () {
                            item.addClass(animation + " visibleme");
                        }, animationDelay);
                    });
                }
            }
        },
        masonaryGrid: function () {
            if ($('.story-grid .story-item').length > 0) {
                var $grid = document.querySelector('.story-grid');
                var $masonry = new Masonry($grid, {
                    itemSelector: '.story-item',
                    columnWidth: '.story-sizer',
                    horizontalOrder: true,
                    percentPosition: true,
                });
                $masonry.layout();
                this.autoPaging($masonry);
            }

            if ($('.story-list .story-item').length > 0) {
                this.autoPaging(false);
            }
        },
        convertToSlug: function (string) {
            const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
            const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
            const p = new RegExp(a.split('').join('|'), 'g')

            return string.toString().toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                .replace(/&/g, '-and-') // Replace & with 'and'
                .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                .replace(/\-\-+/g, '-') // Replace multiple - with single -
                .replace(/^-+/, '') // Trim - from start of text
                .replace(/-+$/, '') // Trim - from end of text
        },
        logoutInit: function () {
            $('.logout-form').on('click', '.signoutBtn', function (e) {
                e.preventDefault();
                if (FB) {
                    FB.getLoginStatus(function (response) {
                        if (response && response.status === 'connected') {
                            FB.logout();
                        }
                    });
                }
                $('.logout-form').submit();
            });
        },
        get_nav: function () {
            if (variables && variables.get_nav) {
                var $this = this;
                $('#user-nav-wrapper').load(variables.get_nav, function () {
                    $this.logoutInit();
                });
            }
        },
        autoPaging: function ($masonry) {
            if ($('.auto-paging').length) {
                if ($('.auto-paging li:last-child > a').length) {
                    var options = {
                        path: '.auto-paging li:last-child > a',
                        append: '.story-item',
                        outlayer: $masonry,
                        history: false,
                        hideNav: '.auto-paging .pagination',
                        status: '.auto-paging .scroller-status',
                    };
                    if ($('.story-grid').length) {
                        var infScroll = new InfiniteScroll('.story-grid', options);
                    }
                    if ($('.story-list').length) {
                        var infScroll = new InfiniteScroll('.story-list', options);
                    }

                    infScroll.on('append', function (response, path, items) {
                        Quizier.reinitOnRequest();
                    });
                    infScroll.on('request', function (path) {
                        Quizier.reinitOnRequest();
                    });
                } else {
                    $('.auto-paging').hide();
                }
            }
        },
        reinitOnRequest: function () {
            myLazyLoad.update();
            if (typeof stickySidebar !== 'undefined') {
                stickySidebar.updateSticky();
            }
        },
        updateLazyload: function () {
            Quizier.reinitOnRequest();
            $.ajaxSetup({
                complete: function () {
                    Quizier.updateLazyload();
                }
            });
        },
        initSocial: function () {
            initSocialStream();
            resizeiFrame();
        },
        initFB: function () {
            if (window.FB && window.fb_app_id) {
                FB.init({
                    appId: fb_app_id,
                    status: true,
                    cookie: true,
                    xfbml: true,
                    version: 'v6.0'
                });
            }
        },
        socalizePage: function () {
            $.getScript('https://assets.pinterest.com/js/pinit_main.js');
            $.getScript('https://embed.redditmedia.com/widgets/platform.js');
            $.getScript('https://embedr.flickr.com/assets/client-code.js');
            $.getScript('https://www.instagram.com/embed.js');
            $.getScript('https://platform.twitter.com/widgets.js');
            $.getScript('https://s.imgur.com/min/embed.js');
            // $.getScript('/themes/default/js/sdk.js');
            Quizier.initSocial();
        },
        socialShare: function () {
            var popupSize = {
                width: 780,
                height: 550
            };

            $(document).on('click', '.social-button', function (e) {
                e.preventDefault();
                var verticalPos = Math.floor(($(window).width() - popupSize.width) / 2),
                    horisontalPos = Math.floor(($(window).height() - popupSize.height) / 2),
                    url = $(this).prop('href');

                var popup = window.open(url, 'social',
                    'width=' + popupSize.width + ',height=' + popupSize.height +
                    ',left=' + verticalPos + ',top=' + horisontalPos +
                    ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');

                if (popup) {
                    popup.focus();
                } else {
                    var win = window.open(url, '_blank');
                    win.focus();
                }
            });
        },
        setupSocial: function (result = false) {
            let Url = $("#canonical-url").length ? $("#canonical-url").attr('href') : window.location.href;
            var title = encodeURIComponent($('.post-title').text());
            if (result) {
                var tempUrl = new URL(Url)
                tempUrl.searchParams.append('result', result.id);
                Url = tempUrl.toString();
                if (result.title) {
                    title = result.title.replace(/<[^>]*>?/gm, '');
                }
            }

            if (((navigator.userAgent.match(/Android|iPhone/i) && !navigator.userAgent.match(/iPod|iPad/i)) ? true : false)) {
                $(".is-whatsapp").parent().removeClass('d-none');
            }

            var UrlEncoded = encodeURIComponent(Url);
            $(".is-facebook").attr('href', "https://www.facebook.com/share.php?u=" + UrlEncoded + "&quote=" + title)
            $(".is-twitter").attr('href', "https://twitter.com/intent/tweet?text=" + title + " " + UrlEncoded)
            $(".is-linkedin").attr('href', "https://www.linkedin.com/shareArticle?mini=true&url=" + UrlEncoded + "&title=" + title)
            $(".is-pinterest").attr('href', "https://pinterest.com/pin/create/link/?url=" + UrlEncoded)
            $(".is-reddit").attr('href', "https://www.reddit.com/submit?url=" + UrlEncoded + "&title=" + title)
            $(".is-whatsapp").attr('href', "whatsapp://send?text=" + title + "%20%0A" + UrlEncoded)
            $(".is-email").attr('href', "mailto:?body=Take a look at this page I found: " + title + ". You can read it here: " + Url)
        },
        sweetError: function (text, callback, options) {
            const defaults = {
                icon: 'error',
                title: i18n.t('i18n.error'),
                text: text,
                confirmButtonText: i18n.t('i18n.ok'),
                allowOutsideClick: false,
                allowEscapeKey: false
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function (result) {
                typeof callback === 'function' && callback();
            });
        },
        sweetErrors: function (html, callback, options) {
            const defaults = {
                icon: 'error',
                title: i18n.t('i18n.error'),
                html: html,
                confirmButtonText: i18n.t('i18n.ok'),
                allowOutsideClick: false,
                allowEscapeKey: false
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function (result) {
                typeof callback === 'function' && callback();
            });
        },
        sweetInfo: function (text, callback, options) {
            const defaults = {
                icon: 'info',
                title: i18n.t('i18n.information'),
                text: text,
                confirmButtonText: i18n.t('i18n.ok'),
                allowOutsideClick: false,
                allowEscapeKey: false,
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function (result) {
                typeof callback === 'function' && callback();
            });
        },
        sweetSuccess: function (text, callback, options) {
            const defaults = {
                icon: 'success',
                title: i18n.t('i18n.success'),
                text: text,
                timer: 2000,
                confirmButtonText: i18n.t('i18n.ok'),
                allowOutsideClick: false,
                allowEscapeKey: false,
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function (result) {
                typeof callback === 'function' && callback();
            });
        },
        sweetPrompt: function (text, callback, options) {
            const defaults = {
                title: text,
                input: 'text',
                confirmButtonText: i18n.t('i18n.ok'),
                showCancelButton: true,
                cancelButtonText: i18n.t('i18n.cancel'),
                allowOutsideClick: false,
                allowEscapeKey: false,
                inputValidator: function (value) {
                    if (!value) {
                        return i18n.t('i18n.valueIsRequired');
                    }
                }
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function (result) {
                if (result.value) {
                    typeof callback === 'function' && callback(result);
                }
            });
        },
        follow: function (action, $that) {
            if (action.detached.length) {
                $that.addClass('btn-translucent-primary').removeClass('btn-gradient').text(i18n.t('i18n.followTitle'));
            } else if (action.attached.length) {
                $that.addClass('btn-gradient').removeClass('btn-translucent-primary').text(i18n.t('i18n.followingTitle'));
            }
        },
        followable: function (url, callback) {
            if ($('.followable-button').length) {
                const $self = this;
                $(document).on('click', '.followable-button', function () {
                    const $this = $(this);

                    var action = {};
                    action['action'] = $this.data('action');
                    action['id'] = $this.data('id');
                    action['url'] = url;

                    if (action['action'] == '' || !url) {
                        $self.sweetError(i18n.t('i18n.somethingWentWrong'));
                        return false;
                    }

                    axios.post(url, action).then(res => {
                        if (res.data.reload) {
                            location.reload();
                        }
                        if (res.data.success) {
                            if (action['action'] == 'follow') {
                                $self.follow(res.data.action, $this);
                            }
                            typeof callback === 'function' && callback(res.data);
                        }
                    }).catch(err => {
                        Quizier.sweetError(err.response.data.message);
                        return false;
                    });

                    return false;
                });
            }
        },
    }
}();

var wow = new WOW({
    boxClass: 'wfit'
});
wow.init();

window.Quizier = Quizier;
$(document).ready(function () {
    Quizier.init();

    $.getScript('https://connect.facebook.net/en_US/sdk.js', function () {
        Quizier.initFB();
    });

    $('body').addClass('quizier-initialized');
});
